@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.blinking-period {
    animation: blink 1s infinite;
}

.blinking {
    animation: blink 1s infinite;
}

.icon {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}