.terminal-text {
    display: flex;
    align-items: center;
    font-size: 8vw;
    color: rgb(34 197 94);
    font-family: 'vt323', monospace;
}

.its-gween {
    color: rgb(34 197 94);
}

.terminal-letter {
    display: none;
    transition: all 0.2s;
    font-size: 8vw;
}

/* Add this */
.terminal-letter[data-value=" "] {
    width: 1ch;  /* ch is a unit relative to the width of the "0" character */
}

body {
    background: #111;
    color: white;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  #switch {
    display: none;
  }
  
  .switch-label {
    display: inline-block;
    cursor: pointer;
    background: #fff;
    color: #111;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    margin-top: 10px;
    box-shadow: 0 2px #666;
  }
  .switch-label::after {
    content: "on";
  }
  .switch-label::before {
    content: " ";
    display: inline-block;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    background: #003321;
    margin-right: 10px;
    border: 1px solid #111;
  }
  .switch-label:active {
    box-shadow: none;
    transform: translate3d(0, 2px, 0);
  }
  
  
  @keyframes flicker {
    0% {
      opacity: 1.0;
    }
    5% {
      opacity: 1.0;
    }
    10% {
      opacity: 1.0;
    }
    15% {
      opacity: 1.0;
    }
    20% {
      opacity: 1.0;
    }
    25% {
      opacity: 1.0;
    }
    30% {
      opacity: 1.0;
    }
    35% {
      opacity: 1.0;
    }
    40% {
      opacity: 1.0;
    }
    45% {
      opacity: 1.0;
    }
    50% {
      opacity: 1.0;
    }
    55% {
      opacity: 1.0;
    }
    60% {
      opacity: 1.0;
    }
    65% {
      opacity: 1.0;
    }
    70% {
      opacity: 1.0;
    }
    75% {
      opacity: 1.0;
    }
    80% {
      opacity: 1.0;
    }
    85% {
      opacity: 1.0;
    }
    90% {
      opacity: 1.0;
    }
    95% {
      opacity: 1.0;
    }
    100% {
      opacity: 1.0;
    }
  }
  .container {
    width: 100vw; 
    height: 100vh; 
    margin: 0;  
    border: none;  
  }

  .container::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    animation: flicker 3s infinite;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
  }
  .container::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }
  
  
  @keyframes turn-on {
    0% {
      transform: scale(1, 0.8) translate3d(0, 0, 0);
      -webkit-filter: brightness(30);
      filter: brightness(30);
      opacity: 1;
    }
    3.5% {
      transform: scale(1, 0.8) translate3d(0, 100%, 0);
    }
    3.6% {
      transform: scale(1, 0.8) translate3d(0, -100%, 0);
      opacity: 1;
    }
    9% {
      transform: scale(1.3, 0.6) translate3d(0, 100%, 0);
      -webkit-filter: brightness(30);
      filter: brightness(30);
      opacity: 0;
    }
    11% {
      transform: scale(1, 1) translate3d(0, 0, 0);
      -webkit-filter: contrast(0) brightness(0);
      filter: contrast(0) brightness(0);
      opacity: 0;
    }
    100% {
      transform: scale(1, 1) translate3d(0, 0, 0);
      -webkit-filter: contrast(1) brightness(1.2) saturate(1.3);
      filter: contrast(1) brightness(1.2) saturate(1.3);
      opacity: 1;
    }
  }
  @keyframes turn-off {
    0% {
      transform: scale(1, 1.3) translate3d(0, 0, 0);
      -webkit-filter: brightness(1);
      filter: brightness(1);
      opacity: 1;
    }
    60% {
      transform: scale(1.3, 0.001) translate3d(0, 0, 0);
      -webkit-filter: brightness(10);
      filter: brightness(10);
    }
    100% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: scale(0, 0.0001) translate3d(0, 0, 0);
      -webkit-filter: brightness(50);
      filter: brightness(50);
    }
  }
  .screen {
    width: 100%;
    height: 100%;
    border: none;
    animation: turn-on 4s linear; 
    animation-fill-mode: forwards;
}

  
  @keyframes overlay-anim {
    0% {
      visibility: hidden;
    }
    20% {
      visibility: hidden;
    }
    21% {
      visibility: visible;
    }
    100% {
      visibility: hidden;
    }
  }
  .overlay {
    color: #00FF00;
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 60px;
    visibility: hidden;
    pointer-events: none;
  }
  
  #switch:checked ~ .container .overlay {
    animation: overlay-anim 5s linear;
    animation-fill-mode: forwards;
  }

  .screen-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;  /* Ensure it appears above the iframe but below the overlay */
    text-align: center;
}

@keyframes blink {
    0%, 49% {
        opacity: 1;
    }
    50%, 100% {
        opacity: 0;
    }
}

.blink-cursor {
    animation: blink 1s infinite;
}

.terminal-container {
    /* ... existing styles ... */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Assuming full screen height */
}

.loading-display {
    text-align: center;
}

.progress-container {
    margin-top: 10px; /* Adjust to your liking */
    width: 80%; /* Takes 80% width of parent, adjust to your liking */
    margin-left: auto;
    margin-right: auto;
    opacity: 0; /* Initially hidden */
}

.progress-bar {
    height: 20px; /* Increased size */
    background-color: #0f0; /* Assuming green for now */
    transition: opacity 0.5s;
    opacity: 100; /* Initially hidden */
}

.progress-bar.fade-in {
    opacity: 1;
}

.error-text {
    color: red;
    text-align: center;
    font-size: 8rem; /* Adjust as needed */
}

.error-message {
    text-align: center;
    color: red;
    margin-top: 2px;
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 0.5s forwards;
}
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

/* CRT container */
.crt {
    position: relative; 
    overflow: hidden; 
}

.crt::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    animation: flicker 3s infinite;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
}

.crt::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
}

.icon {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}